import { Icon } from '@audi/audi-ui-react';
import React from 'react';
import { StyledListItem, StyledMoreDropDown } from './NavigationStyles';

interface PriorityDropDownItemProps {
  handlePriorityDropDownClick: () => void;
  activePriortyDropDown: boolean;
  isMobile?: boolean;
  priorityNavLoaded?: boolean;
  priorityItemslength?: number;
}

const PriorityDropDownItem = ({
  handlePriorityDropDownClick,
  activePriortyDropDown,
  isMobile,
  priorityNavLoaded,
  priorityItemslength,
}: PriorityDropDownItemProps) => {
  return (
    <StyledListItem
      id="moreMenuList"
      role="none"
      $isMobile={isMobile}
      $priorityNavLoaded={priorityNavLoaded}
      $priorityItemslength={priorityItemslength}
      $hidePriorityDropDown={priorityItemslength ? priorityItemslength > 0 : false}
    >
      <StyledMoreDropDown
        onClick={handlePriorityDropDownClick}
        $activePriortyDropDown={activePriortyDropDown}
        $priorityNavLoaded={priorityNavLoaded}
      >
        <Icon name="arrow-up" size="large" variant="primary" />
      </StyledMoreDropDown>
    </StyledListItem>
  );
};

export default PriorityDropDownItem;
