/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { UeElement } from '@oneaudi/falcon-tools';
import React from 'react';
import { ItemLinkProps } from '../../../interfaces';
import { useInPageNavContext } from '../../context';
import { StyledLink } from './NavigationStyles';

export const ItemLink = ({
  index,
  path,
  handleSelectedItem,
  hasSubMenu,
  subLevelChildLabel,
  title,
  isLastElement,
  hasParentElement,
  isSecondaryLevel,
  prioprityNavigationActiveIndex,
  target,
}: ItemLinkProps) => {
  const { sameLevelActiveLink, topLevelActiveLink, secondLevelActiveLink, subNavActive } =
    useInPageNavContext();
  const currentPath = typeof window !== 'undefined' ? window?.location?.pathname : '';
  let isActive =
    secondLevelActiveLink?.href === currentPath &&
    secondLevelActiveLink?.urlIndex === index &&
    !subNavActive;
  if (subNavActive) {
    isActive =
      secondLevelActiveLink?.href === currentPath &&
      secondLevelActiveLink?.urlIndex === index &&
      topLevelActiveLink?.index === secondLevelActiveLink?.index;
  }

  const topLevelActive = topLevelActiveLink && index === topLevelActiveLink?.index && !subNavActive;
  const activeTopLevel =
    secondLevelActiveLink?.href === currentPath &&
    secondLevelActiveLink?.index === index &&
    subNavActive;
  let notActiveClass: boolean = false;
  const overviewActive =
    sameLevelActiveLink?.href === currentPath &&
    index === -1 &&
    subNavActive?.index === sameLevelActiveLink?.index;
  const prioprityNavigationActive = prioprityNavigationActiveIndex === index;

  return (
    <StyledLink
      className={`sibling-link ${prioprityNavigationActive ? 'active-link' : isSecondaryLevel ? `secondary-level-link ${isActive ? 'active-link' : `${overviewActive ? 'active-link' : ''}`}` : `top-level-link ${topLevelActive ? 'active-link' : `${activeTopLevel ? 'active-link' : ''}`}`} ${hasSubMenu ? 'has-sub-menu' : ''} `}
      href={path}
      role="menuitem"
      aria-expanded
      onClick={(e) => {
        e.stopPropagation();
        e?.preventDefault(); // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const element: HTMLElement | null = e?.target?.parentElement;
        const classActive = element?.classList;
        const hasSubMenuClass = classActive?.contains('has-sub-menu');
        notActiveClass = !classActive?.contains('active-link');

        if (handleSelectedItem && hasSubMenuClass) {
          handleSelectedItem({
            id: `${title?.replace(/ /g, '-')}-link`,
            index,
            text: title,
            subLevelChildLabel,
            href: path,
            target,
          });
        }

        if (!hasSubMenu && path && notActiveClass) {
          if (target === '_blank') {
            window.open(path);
          } else window.location.href = path;
        }
      }}
      data-index={index}
      $hasParentElement={hasParentElement}
      $isSecondaryLevel={isSecondaryLevel}
      $isLastElement={isLastElement}
      data-testid={`data-testid-${title}`}
      target={target}
    >
      <UeElement type="text" property="Text" label="Navigation item">
        {title}
      </UeElement>
    </StyledLink>
  );
};
