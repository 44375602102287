/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { MouseEvent } from 'react';
import { Icon } from '@audi/audi-ui-react';
import { UeContainer, UeReference } from '@oneaudi/falcon-tools';
import NavBarWrapper from './NavBarWrapper';
import {
  StyledInput,
  StyledSecondaryOverarchingName,
  StyledTextLabel,
  StyledArrowIcon,
  StyledSecondLevelSubMenu,
  StyledListItem,
  StyledMobileListItem,
} from './NavigationStyles';
import { ActiveLinks, Fields, MainLink } from '../../../types';
import { getItemTitle, getLinkTarget, getUrlType } from '../../utils/utils';
import { useInPageDispatch, useInPageNavContext } from '../../context';
import { INPAGENAV_ACTIONS } from '../../reducers/inPageNavReducer';
import { OverViewItem } from './OverViewItem';
import { ItemLink } from './ItemsLink';

interface IProps {
  fields: Fields;
  index: number;
  handleSelectedItem?: (linkObj: ActiveLinks) => void;
  hasParentElement?: boolean;
  fieldsLength: number;
  subNavigation?: MainLink[];
  isSecondaryLevel?: boolean;
  prioprityNavigationActiveIndex?: number;
}

const MobileDropdown = ({
  subNavigation,
  title,
  subLevelChildLabel,
  hasParentElement,
  handleSelectedItem,
  index,
}: {
  subNavigation?: MainLink[];
  title: string;
  subLevelChildLabel?: string;
  hasParentElement?: boolean;
  handleSelectedItem?: (linkObj: ActiveLinks) => void;
  index: number;
}) => {
  const dispatch = useInPageDispatch();
  const { sameLevelActiveLink, topLevelActiveLink, secondLevelActiveLink } = useInPageNavContext();
  return (
    <StyledMobileListItem role="none" className="sibling-item" $hasParentElement={hasParentElement}>
      <StyledInput
        type="checkbox"
        name={`accordion${index}`}
        id={`chck${index}`}
        role="menuitemcheckbox"
        onClick={(e: MouseEvent<HTMLInputElement>) => {
          e?.stopPropagation();
          // @ts-expect-error
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const isChecked: boolean = e.target.checked;
          if (dispatch) {
            dispatch({
              type: INPAGENAV_ACTIONS.TOGGLE_VISIBLE,
              payload: {
                toggleVisible: {
                  visible: isChecked,
                  label: topLevelActiveLink?.text || '',
                  inputName: `accordion${index}`,
                },
              },
            });
          }
        }}
      />
      <UeContainer propertyPath="sameLevelInpageNavigation" label="Navigation item">
        <UeReference propertyPath={`sameLevelInpageNavigation.${index}`} label="Link field">
          <StyledSecondaryOverarchingName
            $hasParentElement={hasParentElement}
            htmlFor={`chck${index}`}
          >
            <StyledTextLabel
              as="span"
              className={`sibling-link ${sameLevelActiveLink?.index === index || secondLevelActiveLink?.index === index ? 'active-link' : ''}`}
              role="menuitem"
              aria-expanded
              onClick={() => {
                if (handleSelectedItem)
                  handleSelectedItem({
                    id: `${title?.replace(/ /g, '-')}-link`,
                    index,
                    text: topLevelActiveLink?.text || '',
                    href: '',
                  });
              }}
              data-index={index}
              $isLastElement={false}
            >
              {title}
            </StyledTextLabel>

            <StyledArrowIcon>
              <Icon name="arrow-up" size="small" variant="primary" />
            </StyledArrowIcon>
          </StyledSecondaryOverarchingName>
        </UeReference>
      </UeContainer>
      {subNavigation && subNavigation?.length > 0 && (
        <StyledSecondLevelSubMenu $hasParentElement={hasParentElement}>
          <NavBarWrapper visible={false} isMobile>
            <OverViewItem
              hasSubMenu={false}
              title={subLevelChildLabel || 'Overview'}
              path={topLevelActiveLink?.href || ''}
              index={index}
              hasParentElement={hasParentElement}
              isSecondaryLevel
              isLastElement={false}
              isMobile
            />
            {subNavigation?.map((item, i) => {
              // page flexible or external link
              const titleTxt = item.fields?.Text;
              const url = getUrlType(item.fields?.href?.path, item.fields?.linkUrl);
              const target: string = getLinkTarget(item.fields);
              return (
                <NavigationItem
                  hasSubMenu={false}
                  title={titleTxt}
                  path={url}
                  index={i}
                  hasParentElement={hasParentElement}
                  isSecondaryLevel
                  isLastElement={i === subNavigation.length - 1}
                  target={target}
                />
              );
            })}
          </NavBarWrapper>
        </StyledSecondLevelSubMenu>
      )}
    </StyledMobileListItem>
  );
};

interface NavigationItemProps {
  hasSubMenu: boolean;
  handleSelectedItem?: (linkObj: ActiveLinks) => void;
  path: string;
  title: string;
  index: number;
  hasParentElement?: boolean;
  subLevelChildLabel?: string;
  isLastElement: boolean;
  isSecondaryLevel?: boolean;
  isMobile?: boolean;
  prioprityNavigationActiveIndex?: number;
  target?: string;
}

export const NavigationItem = ({
  hasSubMenu,
  path,
  title,
  index,
  handleSelectedItem,
  hasParentElement,
  subLevelChildLabel,
  isLastElement,
  isSecondaryLevel,
  isMobile,
  prioprityNavigationActiveIndex,
  target,
}: NavigationItemProps) => {
  const { topLevelActiveLink } = useInPageNavContext();

  return (
    <StyledListItem
      role="none"
      className={`sibling-item ${index === -1 ? 'sub-level-parent' : ''}`}
      $hasParentElement={hasParentElement}
      $isSecondaryLevel={isSecondaryLevel}
      $isMobile={isMobile}
    >
      <UeContainer propertyPath="sameLevelInpageNavigation" label="Navigation item">
        <UeReference
          propertyPath={
            isSecondaryLevel
              ? `sameLevelInpageNavigation.${topLevelActiveLink?.index}.subLinks.${index}`
              : `sameLevelInpageNavigation.${index}`
          }
          label="Link field"
        >
          <ItemLink
            index={index}
            path={path}
            handleSelectedItem={handleSelectedItem}
            hasSubMenu={hasSubMenu}
            subLevelChildLabel={subLevelChildLabel}
            title={title}
            isLastElement={isLastElement}
            hasParentElement={hasParentElement}
            isSecondaryLevel={isSecondaryLevel}
            prioprityNavigationActiveIndex={prioprityNavigationActiveIndex}
            target={target}
          />
        </UeReference>
      </UeContainer>
    </StyledListItem>
  );
};

const SubLevelNavItems = (props: IProps) => {
  const { subNavigation, fields, index, hasParentElement, handleSelectedItem, fieldsLength } =
    props;
  // title / label for anchor tag elements
  const title: string = getItemTitle(fields);

  // page flexible or external link
  const url = getUrlType(fields?.href?.path, fields?.linkUrl);

  // label on secondary level CFM
  const subLevelChildLabel = fields?.subLevelChildLabel;

  const target: string = getLinkTarget(fields);
  return (
    <>
      {/* mobile dropdown accordion menu */}
      <MobileDropdown
        subNavigation={subNavigation}
        title={title}
        subLevelChildLabel={subLevelChildLabel}
        hasParentElement={hasParentElement}
        handleSelectedItem={handleSelectedItem}
        index={index}
      />
      {/* for desktop toggle menu for secondary level anchor links */}
      <NavigationItem
        {...{
          ...props,
          subLevelChildLabel,
          hasSubMenu: true,
          hasParentElement: false,
          path: url,
          title,
          isLastElement: index === fieldsLength - 1,
          isMobile: true,
          target,
        }}
      />
    </>
  );
};

const NavItems = (props: IProps) => {
  const { fields, index, hasParentElement, fieldsLength, prioprityNavigationActiveIndex } = props;

  // title / label for anchor tag elements
  const title: string = getItemTitle(fields);

  // page flexible or external link
  const url = getUrlType(fields?.href?.path, fields?.linkUrl);

  const target: string = getLinkTarget(fields);

  return fields?.subLinks && fields?.subLinks?.length > 0 ? (
    <SubLevelNavItems {...props} />
  ) : (
    <>
      {/* samelevel / sublevel standard menu item Desktop and Mobile */}
      <NavigationItem
        {...{
          ...props,
          hasSubMenu: false,
          path: url,
          hasParentElement,
          title,
          isLastElement: index === fieldsLength - 1,
          prioprityNavigationActiveIndex,
          target,
        }}
      />
    </>
  );
};

export default NavItems;
