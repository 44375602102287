import { InPageNavConfig } from '../../types';

export type InPageNavAction = {
  type: string;
  payload: InPageNavConfig;
};

export const INPAGENAV_ACTIONS = {
  SAME_LEVEL_ACTIVE_LINK: 'SAME_LEVEL_ACTIVE_LINK',
  TOP_LEVEL_ACTIVE_LINK: 'TOP_LEVEL_ACTIVE_LINK',
  SUB_LEVEL_ACTIVE_LINK: 'SUB_LEVEL_ACTIVE_LINK',
  IS_LOADED_FROM_URL: 'IS_LOADED_FROM_URL',
  TOGGLE_VISIBLE: 'TOGGLE_VISIBLE',
  PRIORITY_NAV_LOADED: 'PRIORITY_NAV_LOADED',
  PRIORITY_NAV_ACTIVE: 'PRIORITY_NAV_ACTIVE',
};

const updateLinkItem = (
  state: InPageNavConfig,
  payload: InPageNavConfig,
  type: string,
): InPageNavConfig => {
  if (payload.sameLevelActiveLink && type === 'same') {
    return { ...state, sameLevelActiveLink: payload.sameLevelActiveLink };
  }
  if (payload.topLevelActiveLink && type === 'top') {
    return {
      ...state,
      topLevelActiveLink: payload.topLevelActiveLink,
      subNavActive: payload.subNavActive,
    };
  }
  if (payload.secondLevelActiveLink && type === 'sub') {
    return { ...state, secondLevelActiveLink: payload.secondLevelActiveLink };
  }
  return state;
};

const updatedFromUrl = (state: InPageNavConfig, payload: InPageNavConfig): InPageNavConfig => {
  return { ...state, isLoadedFromUrl: payload.isLoadedFromUrl };
};

const updateToggle = (state: InPageNavConfig, payload: InPageNavConfig): InPageNavConfig => {
  return { ...state, toggleVisible: payload.toggleVisible };
};

const updatePriorityNavLoaded = (
  state: InPageNavConfig,
  payload: InPageNavConfig,
): InPageNavConfig => {
  return {
    ...state,
    priorityNavLoaded: payload.priorityNavLoaded,
  };
};

const updatePriorityNavActive = (
  state: InPageNavConfig,
  payload: InPageNavConfig,
): InPageNavConfig => {
  return {
    ...state,
    prioprityNavActive: payload.prioprityNavActive,
  };
};

export const inPageNavReducer = (
  state: InPageNavConfig,
  action: InPageNavAction,
): InPageNavConfig => {
  switch (action.type) {
    case INPAGENAV_ACTIONS.SAME_LEVEL_ACTIVE_LINK:
      return updateLinkItem(state, action.payload, 'same');
    case INPAGENAV_ACTIONS.TOP_LEVEL_ACTIVE_LINK:
      return updateLinkItem(state, action.payload, 'top');
    case INPAGENAV_ACTIONS.SUB_LEVEL_ACTIVE_LINK:
      return updateLinkItem(state, action.payload, 'sub');
    case INPAGENAV_ACTIONS.IS_LOADED_FROM_URL:
      return updatedFromUrl(state, action.payload);
    case INPAGENAV_ACTIONS.TOGGLE_VISIBLE:
      return updateToggle(state, action.payload);
    case INPAGENAV_ACTIONS.PRIORITY_NAV_LOADED:
      return updatePriorityNavLoaded(state, action.payload);
    case INPAGENAV_ACTIONS.PRIORITY_NAV_ACTIVE:
      return updatePriorityNavActive(state, action.payload);
    default:
      return state;
  }
};
