/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import { StyledListItem, StyledOverViewLabel, StyledOverViewLink } from './NavigationStyles';
import { useInPageNavContext } from '../../context';
import { getParentItemHref, getParentItemTitle } from '../../utils/utils';
import { ActiveLinks } from '../../../types';

interface OverViewLabelProps {
  isSecondaryLevel?: boolean;
}

interface LabelProps extends OverViewLabelProps {
  isParentItem?: boolean;
}

const Link = React.memo(() => {
  const { parentPageInpageNavigation, isLoadedFromUrl } = useInPageNavContext();
  const propertyPath = 'parentPageInpageNavigation';
  if (!getParentItemHref(parentPageInpageNavigation)) {
    return <SecondLevelLabel isParentItem />;
  }
  return (
    <StyledOverViewLink
      $hasParentElement
      $isSecondaryLevel={false}
      href={getParentItemHref(parentPageInpageNavigation)}
      className={!isLoadedFromUrl ? 'active-link' : ''}
    >
      <UeContainer propertyPath={propertyPath} label="Navigation item">
        <UeElement type="text" property="subLevelParentLabel" label="Parent Page Item Label">
          {getParentItemTitle(parentPageInpageNavigation)}
        </UeElement>
      </UeContainer>
    </StyledOverViewLink>
  );
});

const SecondLevelLabel = React.memo(({ isParentItem, isSecondaryLevel }: LabelProps) => {
  const {
    parentPageInpageNavigation,
    sameLevelActiveLink,
    topLevelActiveLink,
    secondLevelActiveLink,
    isLoadedFromUrl,
  } = useInPageNavContext();
  const [activeLink, setActivelink] = useState<ActiveLinks>();
  const [label, setLabel] = useState<string>('');

  useEffect(() => {
    if (!isParentItem) {
      setActivelink(isSecondaryLevel ? topLevelActiveLink : secondLevelActiveLink);
    }
  }, [isParentItem]);

  useEffect(() => {
    const overviewLabel = activeLink?.subLevelChildLabel || activeLink?.text || '';

    setLabel(
      activeLink?.parentLabel ||
        overviewLabel ||
        parentPageInpageNavigation?.fields?.subLevelParentLabel ||
        '',
    );
  }, [activeLink, parentPageInpageNavigation]);

  const propertyPath = isSecondaryLevel
    ? `sameLevelInpageNavigation.${activeLink?.index}`
    : 'parentPageInpageNavigation';

  return sameLevelActiveLink?.subLevelChildLabel &&
    sameLevelActiveLink?.href &&
    !topLevelActiveLink &&
    !isParentItem ? (
    <StyledOverViewLink
      $isSecondaryLevel
      $isActive
      $subPageParent
      href={sameLevelActiveLink?.href}
      target={sameLevelActiveLink?.target}
    >
      <UeContainer propertyPath={propertyPath} label="Navigation Item">
        <UeElement type="text" property="Text" label="Parent Page Item Link">
          {sameLevelActiveLink.subLevelChildLabel}
        </UeElement>
      </UeContainer>
    </StyledOverViewLink>
  ) : topLevelActiveLink?.subLevelChildLabel && topLevelActiveLink?.href && !isParentItem ? (
    <StyledOverViewLink
      $isSecondaryLevel
      href={topLevelActiveLink?.href}
      target={topLevelActiveLink?.target}
    >
      <UeContainer propertyPath={propertyPath} label="Navigation Item">
        <UeElement type="text" property="subLevelChildLabel" label="Sub Level Child Label">
          {topLevelActiveLink.subLevelChildLabel}
        </UeElement>
      </UeContainer>
    </StyledOverViewLink>
  ) : (
    <StyledOverViewLabel
      $hasParentElement
      $isSecondaryLevel={isSecondaryLevel}
      className={!isLoadedFromUrl && isParentItem ? 'active-link' : ''}
    >
      <UeContainer propertyPath={propertyPath} label="Navigation item">
        <UeReference propertyPath={propertyPath} label="Label field">
          <UeElement type="text" property="Text" label="Parent Page Item Label">
            {label}
          </UeElement>
        </UeReference>
      </UeContainer>
    </StyledOverViewLabel>
  );
});

const OverViewLabel: React.FC<OverViewLabelProps> = ({ isSecondaryLevel }) => {
  return (
    <StyledListItem
      role="none"
      className="parent-item"
      $isSecondaryLevel={isSecondaryLevel}
      $hasParentElement
      $isMobile
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      {isSecondaryLevel ? <SecondLevelLabel isSecondaryLevel={isSecondaryLevel} /> : <Link />}
    </StyledListItem>
  );
};

export default OverViewLabel;
