/* eslint-disable no-console */
/**
 * This is the entry point for Feature Hub App integration
 */

import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import type { ContentServiceV1 } from '@oneaudi/content-service';
import type { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import type { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import I18nContextComponent from '@oneaudi/i18n-context';
import { I18NServiceV1 } from '@oneaudi/i18n-service';

import App from './FeatureApp';
import { FalconContent, mapContent } from './contentMapping';
import { InPageNavConfig } from '../types';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1 | undefined;
  readonly 's2:serialized-state-manager': SerializedStateManagerV1;
  readonly 'audi-tracking-service': TrackingServiceV2;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'audi-render-mode-service': RenderModeServiceV1;
}

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, FeatureServiceDependencies> = {
  dependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      'audi-content-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
    },
    externals: {
      react: '*',
      'react-dom': '*',
      'styled-components': '*',
      '@feature-hub/react': '*',
      '@audi/audi-ui-react-v2': '*',
    },
  },
  optionalDependencies: {
    featureServices: {
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      'audi-render-mode-service': '^1.0.0',
    },
  },

  create: ({
    featureServices,
  }: FeatureAppEnvironment<FeatureServiceDependencies, InPageNavConfig>) => {
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');

    const contentService = featureServices['audi-content-service'];
    const content = mapContent(contentService.getContent() as FalconContent);
    const trackingService = featureServices['audi-tracking-service'];
    const renderModeService = featureServices['audi-render-mode-service'];

    if (trackingService) {
      trackingService.featureAppName = __FEATURE_APP_NAME__;
    }
    const i18nData: I18NServiceV1 = featureServices['dbad:audi-i18n-service'];

    return {
      render: () => {
        return (
          <I18nContextComponent
            featureServices={featureServices}
            i18nData={i18nData}
            scopes={['nemo.common']}
          >
            <UniversalEditorProvider
              contentService={contentService}
              renderModeService={renderModeService}
            >
              <App content={content} trackingService={trackingService} />
            </UniversalEditorProvider>
          </I18nContextComponent>
        );
      },
    };
  },
};

export default featureAppDefinition;
