/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useMemo } from 'react';
import NavBarWrapper from './NavBarWrapper';
import NavItems from './NavItems';
import { useInPageDispatch, useInPageNavContext } from '../../context';
import { INPAGENAV_ACTIONS } from '../../reducers/inPageNavReducer';
import { ActiveLinks, MainLink } from '../../../types';
import { hasSubLinks, isSecondaryLevelFromUrl } from '../../utils/utils';
import OverViewLabel from './OverViewLabel';

interface IProps {
  visible: boolean;
}

const SecondLevelNavDesktop = (props: IProps) => {
  const dispatch = useInPageDispatch();
  const { visible } = props;

  const {
    sameLevelInpageNavigation,
    topLevelActiveLink,
    sameLevelActiveLink,
    secondLevelActiveLink,
    subNavActive,
  } = useInPageNavContext();
  const indx = topLevelActiveLink ? topLevelActiveLink?.index : sameLevelActiveLink?.index || 0; // subNavActive?.index || 0;

  const isSubLevelUrl = isSecondaryLevelFromUrl(secondLevelActiveLink);

  // eslint-disable-next-line consistent-return
  const subLinks: MainLink[] = useMemo(() => {
    // load sublinks from match url and secondLevelActiveLink context
    if (secondLevelActiveLink && isSubLevelUrl) {
      const i = indx >= 0 ? indx : Number(secondLevelActiveLink.index);
      return hasSubLinks(i, sameLevelInpageNavigation);
    }
    return hasSubLinks(indx, sameLevelInpageNavigation);
  }, [sameLevelInpageNavigation, visible, secondLevelActiveLink, subNavActive, indx]);

  const handleSelectedItem = ({ id, index, text, href, target }: ActiveLinks) => {
    dispatch &&
      dispatch({
        type: INPAGENAV_ACTIONS.SUB_LEVEL_ACTIVE_LINK,
        payload: {
          secondLevelActiveLink: { id, index, text, href, target },
        },
      });
  };

  return (
    subLinks?.length > 0 && (
      <NavBarWrapper visible={visible} isSecondaryLevel>
        <OverViewLabel isSecondaryLevel />
        {subLinks?.map((item, i) => {
          const { fields } = item;
          return (
            <NavItems
              // eslint-disable-next-line react/no-array-index-key
              key={`nav-item-${i}`}
              fields={fields}
              index={i}
              hasParentElement={false}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              handleSelectedItem={handleSelectedItem}
              fieldsLength={subLinks?.length}
              isSecondaryLevel
            />
          );
        })}
      </NavBarWrapper>
    )
  );
};

export default SecondLevelNavDesktop;
