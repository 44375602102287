import { useCallback, useState } from 'react';

const useToggle = (initialState?: boolean) => {
  const [visible, setVisibility] = useState<boolean>(initialState || false);

  const toggle = useCallback((value?: boolean | ((prev: boolean) => boolean)) => {
    return value !== undefined ? setVisibility(value) : setVisibility((prev) => !prev);
  }, []);

  return [visible, toggle] as const;
};

export default useToggle;
