/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef } from 'react';
import { Theme } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { ActiveLinks, SameLevelInpageNavigation, SubLevelInpageNavigation } from '../../../types';
import NavItems from './NavItems';
import { useInPageDispatch, useInPageNavContext } from '../../context';
import { INPAGENAV_ACTIONS } from '../../reducers/inPageNavReducer';
import { getTopLevelActiveItemHref, hasSubLinks } from '../../utils/utils';
import { useElementHeight } from '../../hooks/use-element-height';

interface IProps {
  visible: boolean;
  prioprityNavigation: SameLevelInpageNavigation[] | SubLevelInpageNavigation[];
  handleListRef?: (ref: React.RefObject<HTMLElement>) => void;
  activeLink?: number;
}

const StyledMorePopOver = styled.div<{
  $visible?: boolean;
  $hidePriorityDropDown?: boolean;
  $isTallerThanScreen?: boolean;
  $visibleHeight?: number;
}>`
  display: none;
  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.l}px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    min-width: 278px;
    top: 100%;
    right: 0;
    background-color: var(${({ theme }: { theme: Theme }) => theme.colors.background.level['0']});
    padding: var(${({ theme }: { theme: Theme }) => theme.responsive.spacing.m});
    transition-timing-function: var(${({ theme }: { theme: Theme }) => theme.easing});
    transition-duration: 125ms;
    transition-property: transform, opacity;
    filter: var(${({ theme }: { theme: Theme }) => theme.shadow.background.level[2]});
    will-change: filter;
    ${(props) => (props.$visible ? 'opacity: 1; z-index: 1000;' : 'opacity: 0; z-index: -1;')};

    ${(props) =>
      props.$isTallerThanScreen ? `height: ${props.$visibleHeight}px; overflow-y: scroll;` : ''}
  }

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.xl}px) {
    ${(props) => props.$hidePriorityDropDown && `display: none;`};
  }
`;

const StyledNavList = styled.ul`
  display: none;

  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

export const StyledListItem = styled.li`
  @media screen and (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints.m}px) {
    position: relative;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
`;

const PopOverNav = (props: IProps) => {
  const { visible, prioprityNavigation, handleListRef, activeLink } = props;
  const popOverMenuRef = useRef<HTMLUListElement>(null);
  const {
    parentPageInpageNavigation,
    sameLevelInpageNavigation,
    topLevelActiveLink,
    prioprityNavActive,
  } = useInPageNavContext();
  const dispatch = useInPageDispatch();
  useEffect(() => {
    if (dispatch) {
      dispatch({
        type: INPAGENAV_ACTIONS.PRIORITY_NAV_LOADED,
        payload: {
          priorityNavLoaded: prioprityNavigation.length > 0,
        },
      });
    }
  }, [prioprityNavigation]);

  useEffect(() => {
    if (popOverMenuRef?.current) handleListRef?.(popOverMenuRef);
  }, [popOverMenuRef, handleListRef]);

  const heightInfo = useElementHeight(popOverMenuRef);

  const handleSelectedItem = ({ id, index, text, subLevelChildLabel, href }: ActiveLinks) => {
    // get index using the href/path from sameLevelInpageNavigation to trigger the correct second level menu
    const arrIndex = sameLevelInpageNavigation
      ?.map((item, i) => ({ item, i }))
      .filter(({ item }) => item.fields.href?.path === href)
      .map(({ i }) => i);
    const indx = Number(arrIndex?.[0] || index);
    if (dispatch) {
      const url = getTopLevelActiveItemHref(sameLevelInpageNavigation, indx);
      dispatch({
        type: INPAGENAV_ACTIONS.TOP_LEVEL_ACTIVE_LINK,
        payload: {
          topLevelActiveLink: {
            id,
            index: indx,
            text,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            subLevelChildLabel: subLevelChildLabel || topLevelActiveLink?.subLevelChildLabel,
            href: url,
          },
          subNavActive: {
            index: indx,
            subLinks: {
              hasLinks: hasSubLinks(indx, sameLevelInpageNavigation).length > 0,
              active: indx !== topLevelActiveLink?.index,
              text: subLevelChildLabel || text,
            },
          },
        },
      });
    }
  };

  return (
    <StyledMorePopOver
      id="moreMenu"
      $visible={visible}
      $hidePriorityDropDown={prioprityNavigation?.length === 0 && prioprityNavActive?.isDesktop}
      $isTallerThanScreen={heightInfo.isTallerThanScreen}
      $visibleHeight={heightInfo.visibleHeight}
    >
      <StyledNavList role="navigation" ref={popOverMenuRef} className="more-menu">
        {prioprityNavigation?.map((item, i) => {
          const { fields } = item;
          return (
            <NavItems
              // eslint-disable-next-line react/no-array-index-key
              key={`nav-item-${i}`}
              fields={fields}
              index={i}
              hasParentElement={!!parentPageInpageNavigation}
              handleSelectedItem={handleSelectedItem}
              fieldsLength={prioprityNavigation.length}
              subNavigation={fields?.subLinks}
              prioprityNavigationActiveIndex={
                activeLink && activeLink >= 0 ? activeLink : undefined
              }
            />
          );
        })}
      </StyledNavList>
    </StyledMorePopOver>
  );
};

export default PopOverNav;
