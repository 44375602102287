/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Theme as AudiTheme } from '@audi/audi-ui-react';
import { ActiveLinks, Fields, Href, InternalLink, SameLevelInpageNavigation } from '../../types';

export const isDarkTheme = (theme: AudiTheme): boolean => theme.name === 'Audi Dark Theme';

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (functionName: unknown): boolean => typeof functionName === 'function';

export const hasSubLinks = (
  index: number,
  sameLevelInpageNavigation: SameLevelInpageNavigation[] | undefined,
) => {
  return sameLevelInpageNavigation?.[index]?.fields?.subLinks || [];
};

export const isParentElement = (parentPageInpageNavigation?: InternalLink): boolean => {
  return parentPageInpageNavigation !== undefined && parentPageInpageNavigation !== null;
};

export const getParentItemTitle = (parentPageInpageNavigation?: InternalLink) => {
  return parentPageInpageNavigation?.fields?.subLevelParentLabel || '';
};

export const getTopLevelActiveItemHref = (
  sameLevelInpageNavigation: SameLevelInpageNavigation[] | undefined,
  index: number,
): string => {
  return getUrlType(
    sameLevelInpageNavigation?.[index]?.fields.href?.path,
    sameLevelInpageNavigation?.[index]?.fields.linkUrl,
  );
};

export const getParentItemHref = (parentPageInpageNavigation?: InternalLink) => {
  return parentPageInpageNavigation?.fields?.internalLink?.path || '';
};

export const getContentPathHref = (itemInpageNavigation?: Href) => {
  return itemInpageNavigation?.contentPath || '';
};

export const getMainItemHref = (itemInpageNavigation?: Href) => {
  return itemInpageNavigation?.path || '';
};

export const getItemHref = (itemInpageNavigation?: Href) => {
  return itemInpageNavigation?.path || '';
};

export const getItemTitle = (fields: Fields) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (fields.Text as string) || '';
};

export const getLinkTarget = (fields: Fields) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (fields.Target as string) || '_self';
};

export const getSubLevelChildLabel = (fields: Fields) => {
  return fields?.subLevelChildLabel || '';
};

export const isSecondaryLevelFromUrl = (secondLevelActiveLink: ActiveLinks | undefined) =>
  typeof secondLevelActiveLink?.index === 'number' && secondLevelActiveLink.index > -1;

export function getElementsByClass(className: string): HTMLCollectionOf<Element> {
  return document.getElementsByClassName(className);
}

export function getUrlType(pageFlexibleUrl: string | undefined, externalLinkUrl?: string): string {
  if (!pageFlexibleUrl && externalLinkUrl) {
    const url = externalLinkUrl.replace(/\s/g, '');
    return url;
  }
  return pageFlexibleUrl || '';
}

export function throttle<T extends (...args: any[]) => void>(
  func: T,
  interval: number,
): (...args: Parameters<T>) => void {
  let lastCall = 0;
  return function throttledFunction(this: any, ...args: Parameters<T>): void {
    const now = Date.now();
    if (now - lastCall >= interval) {
      lastCall = now;
      func.apply(this, args);
    }
  };
}
