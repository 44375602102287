import { ContentFragment } from '@oneaudi/falcon-tools';

export interface FalconContent extends Partial<ContentFragment> {
  __type: 'aem-headless';
  fields: {
    subLevelInpageNavigation: [];
    sameLevelInpageNavigation: [];
    accordionLabel: string;
    uiTheme: string;
    parentPageInpageNavigation: string;
    subLevelParentLabel: string;
    internalLink: string;
    mainLink: string;
    subLinks: string;
    subLevelChildLabel: string;
    Text: string;
    href: string;
    Target: string;
  };
}

export function mapContent(content: FalconContent): FalconContent {
  if (content?.__type !== 'aem-headless') {
    throw new Error('Invalid content type');
  }

  return normalizedContent(content);
}

const normalizedContent = (content: FalconContent): FalconContent => {
  const { fields } = content;
  const {
    subLevelInpageNavigation,
    sameLevelInpageNavigation,
    accordionLabel,
    uiTheme,
    parentPageInpageNavigation,
    subLevelParentLabel,
    internalLink,
    mainLink,
    subLinks,
    subLevelChildLabel,
    Text,
    href,
    Target,
  } = fields;

  const formattedContent: FalconContent = {
    __type: 'aem-headless',
    fields: {
      subLevelInpageNavigation,
      sameLevelInpageNavigation,
      accordionLabel,
      uiTheme,
      parentPageInpageNavigation,
      subLevelParentLabel,
      internalLink,
      mainLink,
      subLinks,
      subLevelChildLabel,
      Text,
      href,
      Target,
    },
  };

  return formattedContent;
};
