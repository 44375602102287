import React, { useEffect, useState } from 'react';

export const useElementHeight = (ref: React.RefObject<HTMLElement>) => {
  const [heightInfo, setHeightInfo] = useState({
    elementHeight: 0,
    screenHeight: 0,
    isTallerThanScreen: false,
    visibleHeight: 0,
  });

  useEffect(() => {
    const checkHeight = () => {
      if (ref.current) {
        const elementRect = ref.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        setHeightInfo({
          elementHeight: elementRect.height,
          screenHeight: viewportHeight,
          isTallerThanScreen: elementRect.height > viewportHeight,
          visibleHeight:
            Math.min(elementRect.bottom, viewportHeight) - Math.max(elementRect.top, 0),
        });
      }
    };

    const debouncedCheckHeight = (() => {
      let timeoutId: number | null = null;
      return () => {
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(checkHeight, 250) as unknown as number;
      };
    })();

    checkHeight();
    window.addEventListener('resize', debouncedCheckHeight);

    return () => {
      window.removeEventListener('resize', debouncedCheckHeight);
    };
  }, [ref]);

  return heightInfo;
};
