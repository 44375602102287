import React, { ReactNode, useEffect, useRef } from 'react';
import { StyledNavBar, StyledNavList } from './NavigationStyles';

const NavBarWrapper = ({
  visible,
  isSecondaryLevel = false,
  isMobile = false,
  children,
  handleListRef,
}: {
  visible: boolean;
  isSecondaryLevel?: boolean;
  isMobile?: boolean;
  children: ReactNode;
  handleListRef?: (ref: React.RefObject<HTMLElement>) => void;
}) => {
  const navigationRef = useRef<HTMLDivElement>(null);
  const navigationListRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (navigationRef?.current) handleListRef?.(navigationRef);
    if (navigationListRef?.current) handleListRef?.(navigationListRef);
  }, [navigationRef, navigationListRef, handleListRef]);

  return (
    <StyledNavBar
      ref={navigationRef}
      role={isMobile ? 'none' : 'navigation'}
      $visible={visible}
      className={`inpage-nav-menu ${!isMobile ? 'nav-bar-top' : ''}`}
      $isSecondaryLevel={isSecondaryLevel}
    >
      <StyledNavList
        className={`${isSecondaryLevel ? 'sublevel-ul-list' : `${!isMobile ? 'top-ul-list' : ''}`}`}
        ref={navigationListRef}
        role={isMobile ? 'none' : 'menubar'}
      >
        {children}
      </StyledNavList>
      <div className="background-priority-layer">&nbsp;</div>
    </StyledNavBar>
  );
};

export default NavBarWrapper;
