/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import '../../styles/app.css';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { useI18n } from '@oneaudi/i18n-context';
import { PAGE_MARGIN_L, PAGE_MARGIN_XL } from '@audi/audi-ui-design-tokens';
import TopLevelNav from '../navigation/TopLevelNav';
import SecondLevelNavDesktop from '../navigation/SecondLevelNavDesktop';
import useToggle from '../../hooks/use-toggle';
import useActiveLink from '../../hooks/use-active-link';
import { getIndexPosition, useTracking } from '../../hooks/use-tracking';
import { TrackingServiceContext, useInPageNavContext } from '../../context';
import { messages } from '../../i18n/messages';
import { useMount } from '../../hooks/use-mount';
import { getParentItemTitle } from '../../utils/utils';

interface IProps {
  trackingService?: TrackingServiceV2;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: var(${(props) => props.theme.colors.background.level['0']});
  opacity: 1;
  transition: opacity 0.3s ${(props) => props.theme.easing};
  will-change: opacity;
  padding: 0;
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    width: auto;
  }

  div[data-aue-type='container'] {
    width: 100%;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding: 0 ${PAGE_MARGIN_L}px;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    padding: 0 ${PAGE_MARGIN_XL}px;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    padding: 0 calc((100% - 1728px) / 2);
  }
`;

const InpageNav: React.FC<IProps> = () => {
  const [visible, toggle] = useToggle(false);
  const {
    topLevelActiveLink,
    secondLevelActiveLink,
    subNavActive,
    sameLevelInpageNavigation,
    parentPageInpageNavigation,
  } = useInPageNavContext();
  const { trackingService } = useContext(TrackingServiceContext);
  const tracking = useTracking(trackingService);
  const noContentMessage = useI18n(messages.no_navigation);
  const [isSublinksActiveUrl, setIsSublinksActiveUrl] = useState(false);

  useMount(() => {
    tracking?.appReady();
  });
  const hasSubNavlinks = subNavActive?.subLinks?.hasLinks;
  const isSublinksActive = subNavActive?.subLinks?.active;

  useEffect(() => {
    const canToggle =
      topLevelActiveLink?.index === subNavActive?.index && subNavActive?.subLinks?.hasLinks;

    if (canToggle) {
      toggle();
      // keep subLinks nav open when switching between subnav menus
      if (isSublinksActive) toggle(true);

      // tracking for toggle submenu
      if (topLevelActiveLink && topLevelActiveLink.index !== -1) {
        tracking?.toggleNavigation({
          elementName: topLevelActiveLink.id,
          label: `${topLevelActiveLink.text} toggle`,
          clickID: topLevelActiveLink.id,
          targetURL: topLevelActiveLink.href || '',
          value: isSublinksActive ? 'opened' : 'closed',
          pos: `${getIndexPosition(topLevelActiveLink.index)}`,
        });
      }
    }

    // tracking for subnav link clicks
    if (
      topLevelActiveLink &&
      secondLevelActiveLink &&
      secondLevelActiveLink.index !== -1 &&
      hasSubNavlinks
    ) {
      tracking?.navigationItemClick({
        elementName: secondLevelActiveLink.id,
        label: secondLevelActiveLink.text,
        clickID: secondLevelActiveLink.id,
        targetURL: secondLevelActiveLink.href || '',
        pos: `${getIndexPosition(topLevelActiveLink?.index)}-${getIndexPosition(secondLevelActiveLink.index)}`,
        isSubNavigation: isSublinksActive,
      });
    }
    // tracking for topnav link clicks
    if (topLevelActiveLink && topLevelActiveLink?.index !== -1 && !hasSubNavlinks) {
      tracking?.navigationItemClick({
        elementName: topLevelActiveLink.id,
        label: topLevelActiveLink.text,
        clickID: topLevelActiveLink.id,
        targetURL: topLevelActiveLink.href || '',
        pos: `${getIndexPosition(topLevelActiveLink?.index)}`,
        isSubNavigation: isSublinksActive,
      });
    }
  }, [topLevelActiveLink, secondLevelActiveLink]);

  useEffect(() => {
    if (isSublinksActiveUrl) {
      toggle(true);
    }
  }, [isSublinksActiveUrl]);

  useMount(() => {
    tracking?.impression({
      elementName: getParentItemTitle(parentPageInpageNavigation),
      label: parentPageInpageNavigation?.fields?.internalLink?.fields?.Text || '',
      clickID: parentPageInpageNavigation?.fields?.internalLink?.id.toString() || '',
      targetURL: parentPageInpageNavigation?.fields?.internalLink?.fields?.href?.contentPath || '',
      value: parentPageInpageNavigation?.fields?.internalLink?.fields?.Text || '',
      pos: '1',
    });
  });

  // used to render the active links based on url
  useActiveLink(setIsSublinksActiveUrl);

  return (
    <Container data-testid="inpage-navigation" id="inpagenav">
      {sameLevelInpageNavigation && sameLevelInpageNavigation?.length > 0 ? (
        <>
          <TopLevelNav />
          {/* large screens sub level navigation */}
          <SecondLevelNavDesktop visible={visible} />
        </>
      ) : (
        <Text as="p">{noContentMessage}</Text>
      )}
    </Container>
  );
};

export default InpageNav;
