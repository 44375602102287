/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { Dispatch, FC, ReactNode, createContext, useContext, useReducer } from 'react';
import { InPageNavAction, inPageNavReducer } from '../reducers/inPageNavReducer';
import { ITrackingService, InPageNavConfig } from '../../types';

const initialState: InPageNavConfig = {
  uiTheme: 'light',
};

type InPageNavProviderProps = {
  children: ReactNode;
  inPageNavValue?: InPageNavConfig;
};

export const Context = createContext<InPageNavConfig>(initialState);
export const InPageNavDispatchContext = createContext<Dispatch<InPageNavAction> | null>(null);
export const TrackingServiceContext = createContext<ITrackingService>({} as ITrackingService);

export const InPageNavProvider: FC<InPageNavProviderProps> = ({
  children,
  inPageNavValue = initialState,
}) => {
  const [state, dispatch] = useReducer(inPageNavReducer, inPageNavValue);
  return (
    <Context.Provider value={state}>
      <InPageNavDispatchContext.Provider value={dispatch}>
        {children}
      </InPageNavDispatchContext.Provider>
    </Context.Provider>
  );
};

export function useInPageNavContext() {
  return useContext(Context);
}

export function useInPageDispatch() {
  return useContext(InPageNavDispatchContext);
}
