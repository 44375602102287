/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { ActiveSubLevelItem, MainLink } from '../../../types';
import {
  getElementsByClass,
  getItemHref,
  getItemTitle,
  getMainItemHref,
  getSubLevelChildLabel,
  getTopLevelActiveItemHref,
  hasSubLinks,
} from '../../utils/utils';
import { useInPageDispatch, useInPageNavContext } from '../../context';
import { INPAGENAV_ACTIONS } from '../../reducers/inPageNavReducer';

const CLASS_NAME = 'inpage-nav-menu';
const TOP_LEVEL_LINKS_CLASS = 'top-level-link';

/**
 * Compares the last segments of two URLs
 * @param url1 First URL to compare
 * @param url2 Second URL to compare
 * @returns boolean indicating if last segments match
 */
function compareUrlLastSegments(url1: string, url2: string): boolean {
  try {
    /**
     * Gets the last segment of a URL
     * @param url URL to process
     * @returns The last segment of the URL
     */
    if (!url1?.trim() || !url2?.trim()) return false;
    const getLastSegment = (url: string): string => {
      // Remove trailing slash if exists
      const cleanUrl: string = url.replace(/\/+$/, '');
      // Split by '/' and get the last non-empty segment
      const segments: string[] = cleanUrl.split('/').filter(Boolean);
      return segments[segments.length - 1] || '';
    };

    const segment1: string = getLastSegment(url1);
    const segment2: string = getLastSegment(url2);

    return segment1 === segment2;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error comparing URLs:', error instanceof Error ? error.message : error);
    return false;
  }
}

const getLastSegmentPathName = (urlPath: string): string | undefined => {
  const segments = urlPath.split('/');
  const filteredSegments = segments.filter((item) => item.trim() !== '');
  const segmentPath = filteredSegments[filteredSegments.length - 1];
  return segmentPath === '' ? undefined : segmentPath;
};

const useActiveLink = (
  setIsSublinksActiveUrl: (setIsSublinksActiveUrl: boolean) => void,
  navSelector: string = `nav.${CLASS_NAME} a`,
  activeClass: string = 'active-link',
): void => {
  const dispatch = useInPageDispatch();
  const { sameLevelInpageNavigation, sameLevelActiveLink, priorityNavLoaded } =
    useInPageNavContext();
  const [activeSubLevelItems, setActiveSubLevelItems] = useState<ActiveSubLevelItem[]>([]);

  // First check top level links
  useEffect(() => {
    const currentPath = typeof window !== 'undefined' ? window?.location?.pathname : '';
    const activeItems: ActiveSubLevelItem[] = [];

    if (sameLevelInpageNavigation && sameLevelInpageNavigation?.length > 0) {
      const topNavLinks: HTMLCollectionOf<HTMLAnchorElement> = getElementsByClass(
        TOP_LEVEL_LINKS_CLASS,
      ) as HTMLCollectionOf<HTMLAnchorElement>;
      sameLevelInpageNavigation?.forEach((item, i) => {
        const href = getMainItemHref(item.fields.href);

        const isComparedUrl = compareUrlLastSegments(currentPath, href);

        if (isComparedUrl) {
          const linkPath = href ? getLastSegmentPathName(href) : undefined;
          // We only need one active link element
          const linkElement = linkPath
            ? Array.from(topNavLinks).filter((link) => link.href.includes(linkPath))
            : [];

          const activeLink: HTMLAnchorElement | null = linkElement?.[0];
          if (activeLink) {
            activeLink.classList.add(activeClass);
            if (priorityNavLoaded) {
              if (dispatch) {
                const url = getTopLevelActiveItemHref(sameLevelInpageNavigation, i);

                dispatch({
                  type: INPAGENAV_ACTIONS.SAME_LEVEL_ACTIVE_LINK,
                  payload: {
                    sameLevelActiveLink: {
                      id: item.id,
                      index: i,
                      text: getItemTitle(item.fields),
                      subLevelChildLabel: getSubLevelChildLabel(item.fields),
                      href: url,
                      // @ts-expect-error
                      target: item.fields.Target as string | undefined,
                    },
                  },
                });
              }
            }
          }

          if (dispatch) {
            const url = getTopLevelActiveItemHref(sameLevelInpageNavigation, i);

            dispatch({
              type: INPAGENAV_ACTIONS.SAME_LEVEL_ACTIVE_LINK,
              payload: {
                sameLevelActiveLink: {
                  id: item.id,
                  index: i,
                  text: getItemTitle(item.fields),
                  subLevelChildLabel: getSubLevelChildLabel(item.fields),
                  href: url,
                  // @ts-expect-error
                  target: item.fields.Target as string | undefined,
                },
              },
            });
            dispatch({
              type: INPAGENAV_ACTIONS.IS_LOADED_FROM_URL,
              payload: {
                isLoadedFromUrl: true,
              },
            });
          }
        }

        // populate second level links
        const subLinks: MainLink[] = hasSubLinks(i, sameLevelInpageNavigation);
        const isSubLinks = subLinks.length > 0;
        if (isSubLinks) {
          const activeSubLevelItem: ActiveSubLevelItem = {
            id: item.id,
            parentLabel: getItemTitle(item.fields),
            subLevelChildLabel: getSubLevelChildLabel(item.fields),
            index: i,
            subLevelInpageNavigation: subLinks,
          };
          activeItems.push(activeSubLevelItem);
        }
      });

      setActiveSubLevelItems(activeItems);
    }
  }, [priorityNavLoaded, navSelector, activeClass]);

  useEffect(() => {
    if (sameLevelActiveLink && sameLevelActiveLink?.subLevelChildLabel) {
      setIsSublinksActiveUrl(true);
    }
  }, [sameLevelActiveLink]);

  // Now check if url matches any second level links
  useEffect(() => {
    if (activeSubLevelItems?.length > 0) {
      const currentPath = typeof window !== 'undefined' ? window?.location?.pathname : '';

      activeSubLevelItems.forEach((subitem: ActiveSubLevelItem) => {
        const overviewLabel = subitem.subLevelChildLabel || subitem.parentLabel;
        subitem.subLevelInpageNavigation?.forEach((item, ii) => {
          const href = getItemHref(item.fields.href);
          const isComparedUrl = compareUrlLastSegments(currentPath, href);

          if (isComparedUrl) {
            // Set to true to activate sublevel toggle function
            setIsSublinksActiveUrl(true);
            if (dispatch) {
              const url = getTopLevelActiveItemHref(sameLevelInpageNavigation, subitem.index);

              dispatch({
                type: INPAGENAV_ACTIONS.TOP_LEVEL_ACTIVE_LINK,
                payload: {
                  topLevelActiveLink: {
                    id: subitem.id,
                    index: subitem.index,
                    text: subitem.parentLabel,
                    subLevelChildLabel: overviewLabel,
                    href: url,
                    target: item.fields.Target as string | undefined,
                  },
                },
              });
              const urlSecond = getTopLevelActiveItemHref(subitem.subLevelInpageNavigation, ii);

              dispatch({
                type: INPAGENAV_ACTIONS.SUB_LEVEL_ACTIVE_LINK,
                payload: {
                  secondLevelActiveLink: {
                    id: item.id,
                    index: subitem.index,
                    text: item.fields.Text,
                    href: urlSecond,
                    parentLabel: overviewLabel,
                    urlIndex: ii,

                    target: item.fields.Target as string | undefined,
                  },
                },
              });
              dispatch({
                type: INPAGENAV_ACTIONS.IS_LOADED_FROM_URL,
                payload: {
                  isLoadedFromUrl: true,
                },
              });
            }
          }
        });
      });
    }
  }, [activeSubLevelItems]);
};

export default useActiveLink;
